import { DEFAULT_USER_AUTH } from "./const";

export const USER_AUTH_KEY = "userauth";

export const getStoredUserAuth = () => {
    const auth = localStorage.getItem(USER_AUTH_KEY);
    if (auth) {
        return JSON.parse(auth);
    }
    return DEFAULT_USER_AUTH;
};

export const setUserAuth = (userAuth) => {
    localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth));
};

export function getItem(key) {
    const token = localStorage.getItem(key);
    return token || "";
}

export function setItem(key, value) {
    localStorage.setItem(key, value);
}

export function removeItem(key) {
    localStorage.removeItem(key);
}

export function clear() {
    localStorage.clear();
}
