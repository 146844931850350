import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
// import { Provider } from "react-redux";
import { config } from "dotenv";
import App from "./App";
import CustomApolloProvider from "./components/CustomApolloProvider";
// import configStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

config();

// const store = configStore();

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <CustomApolloProvider>
            <App />
        </CustomApolloProvider>
    </BrowserRouter>,
);

serviceWorker.register();
