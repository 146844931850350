import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { getStoredUserAuth } from "../utils/LocalStorage";
import { DEFAULT_USER_AUTH } from "../utils/const";
import { useAuthHandler } from "../custom-hooks/AuthHandler";
import { useGlobalStateHandler } from "../custom-hooks/globalStateHandler";

export const AuthContext = createContext({
    auth: DEFAULT_USER_AUTH,
    setAuthStatus: () => {},
    setUnauthStatus: () => {},
    notificationCount: 0,
    setNotificationCount: () => {},
    authComp: "login",
    setAuthComp: () => {},
    categoriesData: [],
    setCategoriesData: () => {},
    isProfile: false,
    setisProfile: () => {},
    toastList: [],
    setToastList: () => {},
    activeCat: 0,
    setActiveCat: () => {},
    isSidebarOpen: true,
    setIsSidebarOpen: () => {},
    activeTabList: [],
    setActiveTabList: () => {},
    languages: [],
    setLanguages: () => {},
    isNotification: false,
    setIsNotification: () => {},
    notificationsData: [],
    setNotificationsData: () => {},
    globalLoading: false,
    setGlobalLoading: () => {},
    contentLoading: false,
    setContentLoading: () => {},
});

export const AuthContextProvider = ({ children }) => {
    const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(getStoredUserAuth());
    const [authComp, setAuthComp] = useState("login");
    const [categoriesData, setCategoriesData] = useState([]);
    const [isProfile, setisProfile] = useState(false);
    const [toastList, setToastList] = useState([]);
    const [activeCat, setActiveCat] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeTabList, setActiveTabList] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationsData, setNotificationsData] = useState([]);
    const [globalLoading, setGlobalLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(false);

    const { notificationCount, setNotificationCountValue } = useGlobalStateHandler();

    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuthStatus,
                setUnauthStatus,
                notificationCount,
                setNotificationCountValue,
                authComp,
                setAuthComp,
                categoriesData,
                setCategoriesData,
                isProfile,
                setisProfile,
                toastList,
                setToastList,
                activeCat,
                setActiveCat,
                isSidebarOpen,
                setIsSidebarOpen,
                activeTabList,
                setActiveTabList,
                languages,
                setLanguages,
                isNotification,
                setIsNotification,
                notificationsData,
                setNotificationsData,
                globalLoading,
                setGlobalLoading,
                contentLoading,
                setContentLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthContextProvider.propTypes = {
    children: PropTypes.node,
};
