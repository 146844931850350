/* eslint-disable no-useless-escape */

import moment from "moment";

export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberFormatter(name, data) {
    data = Math.round(data * 100) / 100;
    return `${name}:${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function currencyFormatter(name, data) {
    data = Math.round(data * 100) / 100;
    const number = formatnumber(data);
    return `${name ? `${name}: ` : ""}${
        !isNaN(number) || number !== "NaN"
            ? `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "--"
    }`;
}

export function percentFormatter(name, data) {
    data = Math.round(data * 100) / 100;
    return `${name}:${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
}

export function capitalize(s) {
    return (s && s[0].toUpperCase() + s.slice(1)) || "";
}

export function replaceLowercase(str) {
    return str.replaceAll(/[-_]+?/g, " ").toLowerCase();
}

export function removeFalsy(obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (prop === "types" && obj[prop].length > 0) {
            newObj[prop] = obj[prop];
        } else if (prop !== "types" && obj[prop]) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
}

export function createColumnValueMap(object) {
    return Object.keys(object).map((key) => {
        return {
            column: key,
            value: object[key],
        };
    });
}

export function sortInSpecificOrder(data, sortOrder, isArray = true) {
    if (isArray) return data.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    return JSON.parse(JSON.stringify(data.length ? data : [data], sortOrder));
}

const unitlist = ["", "K", "M", "G"];
export const formatnumber = (number) => {
    const sign = Math.sign(number);
    let unit = 0;
    while (Math.abs(number) > 1000) {
        unit += 1;
        number = Math.floor(Math.abs(number) / 100) / 10;
    }
    return sign * number + unitlist[unit];
};

export const nFormatter = (num, digits = 2) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find((ele) => {
            return num >= ele.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export function remove(array, key, value) {
    const index = array.findIndex((obj) => obj[key] === value);
    return index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array;
}

export function getFilenameAndExtension(pathfilename) {
    const filenameextension = pathfilename.replace(/^.*[\\\/]/, "");
    const filename = filenameextension.substring(0, filenameextension.lastIndexOf("."));
    const ext = filenameextension.split(".").pop();

    return [filename, ext];
}

export function formatTime(time) {
    // Hours, minutes and seconds
    const hrs = time / 3600;
    const mins = (time % 3600) / 60;
    const secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) {
        ret += `${hrs}:${mins < 10 ? "0" : ""}`;
    }
    ret += `${mins}:${secs < 10 ? "0" : ""}`;
    ret += `${secs}`;
    return ret;
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
    return buildDate;
};

export const UnAuthenticationErrorHandler = (gqlErr, setDataError) => {
    setDataError({ error: true, errorMsg: gqlErr.message });
};

export const camalize = (text) => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
};

export const suppressCount = (count) => {
    return count > 99 ? `99+` : count;
};
