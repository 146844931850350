import React from "react";
import PropTypes from "prop-types";
import Spinner from "../Spinner";
import "./page-loader.scss";

const PageLoader = ({ text }) => {
    return (
        <div className="d-flex align-items-center justify-content-center flex-column">
            <Spinner className="main-loader" />
            <div className="info-text relative-center">{text}</div>
        </div>
    );
};

PageLoader.propTypes = {
    text: PropTypes.string,
};

export default PageLoader;
