import React, { useEffect, Fragment, useState, useContext } from "react";
import "./App.scss";
import { AppRouter } from "./AppRouter";
import { AuthContext, AuthContextProvider } from "./context";
import { BrowserTitleContext, BrowserTitleContextProvider } from "./context/browserTitle";
import { getStoredUserAuth } from "./utils/LocalStorage";
import { useAuthHandler } from "./custom-hooks/AuthHandler";
import withClearCache from "./ClearCache";
import { WsSocketContextProvider } from "./context/wsSocket";
import { ErrorContextProvider } from "./context/errorContext";
import useLogoutHandler from "./custom-hooks/logoutHandler";
import CustomModal from "./components/CustomModal";
import Button from "./components/Button";
import useRedirectHandler from "./custom-hooks/redirectHandler";
import Spinner from "./components/Spinner";
import Feedback from "./components/Feedback/feedback";

const MainApp = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { auth } = useAuthHandler(getStoredUserAuth());
    const { browserTitle } = useContext(BrowserTitleContext);
    const { globalLoading } = useContext(AuthContext);

    const { logout } = useLogoutHandler();
    const { redirectToAdmin, redirectToBase } = useRedirectHandler();

    const getuserinfo = async () => {
        try {
            if (auth && auth.authenticated) {
                redirectToAdmin();
            } else {
                console.log("not signed in");
                redirectToBase();
            }
            setIsLoading(false);
        } catch (err) {
            console.log("error in root page", err);
        }
    };

    useEffect(() => {
        getuserinfo();
        document.title = browserTitle;
    }, []);

    return (
        <Fragment>
            {isLoading ? (
                <Spinner className="main-loader" />
            ) : (
                <Fragment>
                    {globalLoading && (
                        <div className="fadeMe">
                            <p>Logging out please wait....</p>
                            <div id="loader">
                                <div className="application-loading-container">
                                    <div className="application-loading-box">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ErrorContextProvider>
                        {/* <AuthContextProvider> */}
                        <BrowserTitleContextProvider>
                            <WsSocketContextProvider>
                                <AppRouter />
                            </WsSocketContextProvider>
                        </BrowserTitleContextProvider>
                        {/* </AuthContextProvider> */}
                    </ErrorContextProvider>
                </Fragment>
            )}

            <CustomModal
                modalName={"unauthorizedAlert"}
                animation="fade-scale"
                modalSize="modal-sm"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="customModalLabel">
                        Unauthorized Alert
                    </h5>
                </div>
                <div className="modal-body">
                    <div className="alert alert-error">
                        Your unauthorized to access this application
                    </div>
                </div>
                <div className="modal-footer">
                    <Button text="Ok" type="button" className="btn btn-primary" onClick={logout} />
                </div>
            </CustomModal>
            <Feedback position={"bottom"} />
        </Fragment>
    );
};

const ClearCacheComponent = withClearCache(MainApp);

const App = () => {
    return (
        <AuthContextProvider>
            <ClearCacheComponent />
        </AuthContextProvider>
    );
};

export default App;
