import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PageLoader from "./components/PageLoader";

const NotFound = lazy(() => import("./components/NotFound"));
const Adminpanel = lazy(() => import("./containers/AdminPanel"));
const AuthenticateScreen = lazy(() => import("./containers/AuthenticateScreen"));

export const AppRouter = () => (
    <Suspense fallback={<PageLoader text="Please wait while we load your application" />}>
        <Routes>
            <Route path="/" exact element={<AuthenticateScreen />} />
            <Route path="/authenticate" exact element={<AuthenticateScreen />} />
            <Route path="/admin-panel" exact element={<Adminpanel />} />

            <Route element={NotFound} />
        </Routes>
    </Suspense>
);
