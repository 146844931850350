import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function useRedirectHandler() {
    const navigate = useNavigate();

    const redirectToAdmin = useCallback(() => {
        navigate("/admin-panel");
    }, [navigate]);

    const redirectToBase = useCallback(() => {
        navigate("/");
    }, [navigate]);

    return { redirectToBase, redirectToAdmin };
}
