import { useState } from "react";
import { suppressCount } from "../utils/formatter";

export const useGlobalStateHandler = () => {
    const [tableInputValues, setInputTableValues] = useState({});
    const [dropdownConfigValues, setDropdownConfigValues] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);

    const setGlobalValues = (globalValue) => {
        setInputTableValues(globalValue);
    };

    const setGlobalDropdownValues = (globalValue) => {
        setDropdownConfigValues(globalValue);
    };

    const setNotificationCountValue = (count) => {
        setNotificationCount(suppressCount(count));
    };

    return {
        tableInputValues,
        setGlobalValues,
        dropdownConfigValues,
        setGlobalDropdownValues,
        notificationCount,
        setNotificationCountValue,
    };
};
