import { useState } from "react";

export const useBrowserTitle = (initialState) => {
    const [browserTitle, setTitle] = useState(initialState);
    const setBrowserTitle = (title) => {
        setTitle(title);
    };
    return {
        browserTitle,
        setBrowserTitle,
    };
};
