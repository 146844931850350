import { gql } from "@apollo/client";

export const GRAPHQL_SCHEMA = {
    addUser: gql`
        mutation AddUser($input: NewUserInput!) {
            addUser(input: $input) {
                id
                username
                email
                profile_picture
                preference
                status
                cc_status
                user_type
                last_login
                access_token
                access_token_generated_on
                access_token_expires_on
            }
        }
    `,
    verifyEmail: gql`
        mutation VerifyEmail($input: VerifyEmailInput!) {
            verifyEmail(input: $input)
        }
    `,
    verifyCC: gql`
        mutation VerifyCC($email: String!, $approve: Boolean!) {
            verifyCC(email: $email, approve: $approve)
        }
    `,
    authenticateUser: gql`
        mutation AuthenticateUser($input: AuthenticateUserInput!) {
            authenticateUser(input: $input) {
                id
                username
                email
                profile_picture
                preference
                user_type
                status
                cc_status
                last_login
                access_token
                access_token_generated_on
                access_token_expires_on
            }
        }
    `,
    getUserData: gql`
        query GetUsers($name: String, $limit: Int, $offset: Int, $search: String) {
            getUsers(name: $name, limit: $limit, offset: $offset, search: $search) {
                users {
                    id
                    username
                    email
                    profile_picture
                    preference
                    user_type
                    last_login
                    last_uploaded
                    status
                    cc_status
                    last_active
                    report_count
                    posts_count
                    quotes_count
                    request_details {
                        id
                        user_id
                        request_type
                        from
                        to
                        metadata
                        resubmit
                        is_approved
                        is_rejected
                        is_appealed
                        is_appeal_approved
                        is_appeal_rejected
                        is_resubmitted
                        is_resubmit_approved
                        is_resubmit_rejected
                        approved_by_user
                        appeal_approved_by_user
                        resubmission_approved_by_user
                        reason
                        appeal_reason
                        admin_appeal_reason
                        admin_resubmission_reason
                        createdAt
                    }
                }
                count
            }
        }
    `,
    resendVerificationCode: gql`
        mutation ResendActivationCode($email: String!) {
            resendActivationCode(email: $email)
        }
    `,
    logoutUser: gql`
        query LogoutUser {
            logoutUser
        }
    `,
    revokeAccessToken: gql`
        query RevokeUserAccessToken {
            revokeAccessToken {
                username
                email
                user_type
                last_login
                access_token
                access_token_generated_on
                access_token_expires_on
            }
        }
    `,
    forgotPassword: gql`
        mutation ForgotPassword($email: String!) {
            forgotPassword(email: $email)
        }
    `,
    changePassword: gql`
        mutation ChangePassword($input: ChangePasswordInput!) {
            changePassword(input: $input)
        }
    `,
    getCategoriesData: gql`
        query GetCategories {
            getCategories {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    getPresignedUrl: gql`
        mutation GetPresignedUrl($input: [PresignedUrlInput]!) {
            getPresignedUrl(input: $input) {
                url
                file_name
                thumbnail_url
            }
        }
    `,
    uploadComplete: gql`
        mutation UploadComplete($input: [UploadCompleteInput]!, $totalSize: Float!) {
            uploadComplete(input: $input, totalSize: $totalSize)
        }
    `,
    getContents: gql`
        query GetContents(
            $name: String!
            $user_id: String
            $language: String
            $limit: Int
            $offset: Int
        ) {
            getContents(
                name: $name
                user_id: $user_id
                language: $language
                limit: $limit
                offset: $offset
            ) {
                contents {
                    content_id
                    upload_type
                    items {
                        content_path
                        thumbnail_path
                        upload_type
                        content_type
                        content_id
                        order_id
                        scheduled_on
                        file_name
                        content_src
                        thumbnail_src
                        external_links
                    }
                    likes_count
                }
                count
            }
        }
    `,
    deleteContent: gql`
        mutation DeleteContent($input: DeleteContentInput!) {
            deleteContent(input: $input) {
                contents {
                    content_id
                    upload_type
                    items {
                        content_path
                        thumbnail_path
                        upload_type
                        content_type
                        content_id
                        scheduled_on
                        file_name
                        content_src
                        thumbnail_src
                    }
                }
                count
            }
        }
    `,
    reportContent: gql`
        mutation ReportContent($input: ReportContentInput!) {
            reportContent(input: $input)
        }
    `,
    getNotifications: gql`
        query GetNotifications($isAdmin: Boolean, $limit: Int, $offset: Int) {
            getNotifications(isAdmin: $isAdmin, limit: $limit, offset: $offset) {
                notifications {
                    notification_id
                    added_by
                    message
                    to
                    type
                    added_date
                    content_id
                    upload_type
                    items
                }
                count
            }
        }
    `,
    safeContent: gql`
        mutation SafeContent($input: SafeContentInput!) {
            safeContent(input: $input)
        }
    `,
    updateNotification: gql`
        mutation UpdateNotification($input: UpdateNotificationInput!) {
            updateNotification(input: $input)
        }
    `,
    createCategory: gql`
        mutation CreateCategory($input: [CreateCategoryInput]!) {
            createCategory(input: $input) {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    createSubCategory: gql`
        mutation CreateSubCategory($input: [CreateSubCategoryInput]!) {
            createSubCategory(input: $input) {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    updateSubCategory: gql`
        mutation UpdateSubCategory($input: UpdateSubCategoryInput!) {
            updateSubCategory(input: $input) {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    deleteCategory: gql`
        mutation DeleteCategory($name: String!) {
            deleteCategory(name: $name) {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    deleteSubCategory: gql`
        mutation DeleteSubCategory($name: String!, $category_name: String!) {
            deleteSubCategory(name: $name, category_name: $category_name) {
                name
                logo
                sub_categories {
                    name
                    logo
                    description
                }
            }
        }
    `,
    deleteUser: gql`
        mutation DeleteUser($email: String!) {
            deleteUser(email: $email)
        }
    `,
    makeAdmin: gql`
        mutation MakeAdmin($email: String!, $makeAdm: Boolean) {
            makeAdmin(email: $email, makeAdm: $makeAdm)
        }
    `,
    getLanguages: gql`
        query GetLanguages {
            getLanguages {
                name
                code
            }
        }
    `,
    addLanguage: gql`
        mutation AddLanguage($input: AddLanguageInput!) {
            addLanguage(input: $input) {
                name
                code
            }
        }
    `,
    addFeedback: gql`
        mutation AddUserFeedback($input: AddFeedbackInput!) {
            addFeedback(input: $input)
        }
    `,
    getFeedbacks: gql`
        query GetFeedbacks($limit: Int, $offset: Int, $feedbackType: String) {
            getFeedbacks(limit: $limit, offset: $offset, feedbackType: $feedbackType) {
                feedbacks {
                    feedback_id
                    added_by
                    message
                    type
                    added_date
                    from
                }
                count
            }
        }
    `,
    getUserEvents: gql`
        query GetUserEvents($filter: JSON, $limit: Int, $offset: Int) {
            getUserEvents(filter: $filter, limit: $limit, offset: $offset) {
                events {
                    eventId
                    eventName
                    eventType
                    eventTime
                    eventSource
                    resourceType
                    accessRole
                    parameters
                    actionBy
                    timeSpent
                }
                count
            }
        }
    `,

    getUserEventsFilters: gql`
        query GetUserEventsFilters {
            getUserEventsFilters
        }
    `,
    getAttributors: gql`
        query GetAttributors($limit: Int, $offset: Int, $search: String) {
            getAttributors(limit: $limit, offset: $offset, search: $search) {
                attributors {
                    _id
                    name
                    link
                }
                count
            }
        }
    `,
    addAttributors: gql`
        mutation AddUpdateAttributors($action: String!, $input: AddUpdateAttributorsInput!) {
            addUpdateAttributors(action: $action, input: $input)
        }
    `,
    getStatistics: gql`
        query GetStatistics($parameter: String, $date: Array, $value: Int) {
            getStatistics(parameter: $parameter, date: $date, value: $value)
        }
    `,
    getReportContents: gql`
        query GetReportContent($limit: Int, $offset: Int) {
            getReportContent(limit: $limit, offset: $offset) {
                contents {
                    report_id
                    message
                    reported_by
                    from
                    reported_at
                    content_id
                    items {
                        content_path
                        thumbnail_path
                        upload_type
                        content_type
                        content_id
                        scheduled_on
                        file_name
                        content_src
                        thumbnail_src
                        category_name
                        sub_category_name
                        quotes
                        hashtags
                        upload_category
                        duration
                        mentions
                    }
                }
                count
            }
        }
    `,
    getAppUpdates: gql`
        query GetAppUpdates {
            getAppUpdates {
                version
                force_update
            }
        }
    `,
    updateAppUpdate: gql`
        mutation UpdateAppUpdates($input: AppUpdatesInput!) {
            updateAppUpdates(input: $input)
        }
    `,
    getUserRequestDetails: gql`
        query GetUserRequestDetails($id: ID!) {
            getUserRequestDetails(id: $id) {
                metadata
                is_approved
                request_type
                from
                to
            }
        }
    `,
    approveUserRequest: gql`
        mutation ApproveUserRequest($input: ApproveUserRequestInput!) {
            approveUserRequest(input: $input)
        }
    `,
    getAdminActions: gql`
        query GetAdminActions(
            $limit: Int
            $offset: Int
            $actionType: String
            $search: String
            $filter: JSON
        ) {
            getAdminActions(
                limit: $limit
                offset: $offset
                actionType: $actionType
                search: $search
                filter: $filter
            ) {
                actions {
                    action_id
                    action_by
                    message
                    type
                    action_date
                    report_data
                    items
                }
                count
            }
        }
    `,
    deleteContentPermanently: gql`
        mutation DeleteContentPermanently($actionId: ID!) {
            deleteContentPermanently(actionId: $actionId)
        }
    `,
    sendCustomNotification: gql`
        mutation SendCustomNotification($input: CustomNotificationInput!) {
            sendCustomNotification(input: $input)
        }
    `,
};
