import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { removeItem } from "../../utils/LocalStorage";
import "./toast.scss";

const areEqual = (prevProps, nextProps) => {
    return prevProps.toastList === nextProps.toastList;
};

const Toast = memo(
    ({ toastList, position, autoDelete, dismissTime, btnRequired, btnCallback, btnText }) => {
        const [list, setList] = useState(toastList);

        useEffect(() => {
            setList([...toastList]);
            const interval = setInterval(() => {
                if (autoDelete && toastList.length) {
                    deleteToast(toastList[0].id);
                }
            }, dismissTime);

            return () => {
                clearInterval(interval);
            };
        }, [toastList]);

        const deleteToast = (id, btnClick) => {
            /** Do not delete module name if other pages */
            if (window.location.pathname === "/") {
                if (!btnClick) removeItem("ad_module");
            }
            const listItemIndex = list.findIndex((e) => e.id === id);
            const toastListItem = toastList.findIndex((e) => e.id === id);
            list.splice(listItemIndex, 1);
            toastList.splice(toastListItem, 1);
            setList([...list]);
        };

        const handleButton = (id) => {
            deleteToast(id, true);
            btnCallback();
        };

        if (toastList.length === 0 || list.length === 0) return null;

        return (
            <>
                <div className={`notification-container ${position}`}>
                    {list.map((toast, i) => (
                        <div
                            key={i}
                            className={`notification toast ${position}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                        >
                            {/* <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button> */}
                            <div className="notification-image">
                                <img src={toast.icon} alt="" />
                            </div>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                {/* <p className="notification-message">
                                    {toast.description}
                                </p> */}
                            </div>
                            {btnRequired ? (
                                <Button
                                    text={btnText}
                                    className="btn btn-primary request-access"
                                    onClick={() => handleButton(toast.id)}
                                />
                            ) : null}
                            <Button onClick={() => deleteToast(toast.id)}>
                                {<i className="fa fa-times" aria-hidden="true"></i>}
                            </Button>
                        </div>
                    ))}
                </div>
            </>
        );
    },
    areEqual,
);

Toast.defaultProps = {
    position: "top-right",
    autoDelete: true,
    dismissTime: 3000,
    btnRequired: false,
    btnText: "",
};

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number,
    btnRequired: PropTypes.bool,
    btnCallback: PropTypes.func,
    btnText: PropTypes.string,
};

Toast.displayName = "Toast";

export default Toast;
