/* eslint-disable no-useless-escape */

import moment from "moment";
import { HttpLink } from "@apollo/client";

export function objectToQueryString(obj) {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    return str.join("&");
}

export function removeTags(str) {
    if (str === null || str === "") return false;
    str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
}

export function extractContent(s) {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
}

export function formatBytes(a, b = 2) {
    if (a === 0) return "0 Bytes";
    const c = b < 0 ? 0 : b;
    const d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
}

export function findMinMax(arr, attribute, type, dateFormat = "MM/DD/YYYY") {
    if (type === "date") {
        let min = moment(arr[0][attribute], dateFormat).valueOf();
        let max = moment(arr[0][attribute], dateFormat).valueOf();
        for (let i = 1, len = arr.length; i < len; i++) {
            const v = moment(arr[i][attribute], dateFormat).valueOf();
            min = v < min ? v : min;
            max = v > max ? v : max;
        }
        return [min, max];
    }
    let min = arr[0][attribute];
    let max = arr[0][attribute];
    for (let i = 1, len = arr.length; i < len; i++) {
        const v = arr[i][attribute];
        if (v !== "nan") {
            min = v < min ? v : min;
            max = v > max ? v : max;
        }
    }
    return [min, max];
}

export function dateToLocal(date) {
    const local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export const qtrConvertor = (date) => {
    const thisYear = date.getFullYear();
    // const newDate = date.setMonth(date.getMonth() + 1);
    const month = date.getMonth() + 1;
    // console.log(date, date.getMonth(), month, date.getFullYear());

    let quarters = `${thisYear} Q4`;
    if (month >= 2 && month <= 4) {
        quarters = `${thisYear} Q1`;
    } else if (month >= 5 && month <= 7) {
        quarters = `${thisYear} Q2`;
    } else if (month >= 8 && month <= 10) {
        quarters = `${thisYear} Q3`;
    } else if (month >= 11 || month === 1) {
        quarters = `${thisYear} Q4`;
    }

    return quarters;
};

export function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

export function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
}

export async function getThumbnailForVideo(videoUrl) {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    // Trigger video load
    await new Promise((resolve, _) => {
        video.addEventListener("loadedmetadata", () => {
            video.width = video.videoWidth;
            video.height = video.videoHeight;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            // Seek the video to 25%
            video.currentTime = video.duration * 0.25;
        });
        video.addEventListener("seeked", () => resolve());
        video.src = videoUrl;
    });

    // Draw the thumbnailz
    canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL("image/png");
    console.log(imageUrl);
    return imageUrl;
}

export async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export async function blobToFile(theBlob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export async function getVideoCover(file, seekTo = 4.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement("video");
        videoPlayer.setAttribute("src", URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener("error", (ex) => {
            reject(ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener("loadedmetadata", () => {
            // seek to user defined timestamp (in seconds) if possible
            if (videoPlayer.duration < seekTo) {
                reject(new Error("video is too short."));
                return;
            }
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
                videoPlayer.currentTime = seekTo;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener("seeked", () => {
                console.log("video is now paused at %ss.", seekTo);
                // define a canvas to have the same dimension as the video
                const canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;

                // draw the video frame to canvas
                const ctx = canvas.getContext("2d");

                const iw = videoPlayer.width;
                const ih = videoPlayer.height;
                const cw = canvas.width;
                const ch = canvas.height;
                const f = Math.max(cw / iw, ch / ih);

                ctx.setTransform(
                    /*     scale x */ f,
                    /*      skew x */ 0,
                    /*      skew y */ 0,
                    /*     scale y */ f,
                    /* translate x */ (cw - f * iw) / 2,
                    /* translate y */ (ch - f * ih) / 2,
                );

                ctx.drawImage(videoPlayer, 0, 0);
                // ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                // return the canvas image as a blob
                ctx.canvas.toBlob(
                    (blob) => {
                        resolve(blob);
                    },
                    "image/png",
                    0.75 /* quality */,
                );
            });
        });
    });
}

export async function encodeImageFileAsURL(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
        console.log("RESULT", reader.result);
    };
    return reader.readAsDataURL(file);
}

export const getBase64Image = async (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

async function urltoFile(url, filename, mimeType) {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
}

export async function resizeImage(file, targetFileSizeKb, maxDeviation = 50) {
    const dataUrl = await getBase64Image(file);

    if (file.size / 1000 < targetFileSizeKb) return dataUrl; // File is already smaller

    let low = 0.0;
    let middle = 0.5;
    let high = 1.0;

    let result = dataUrl;

    while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = document.createElement("img");

        const promise = new Promise((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = reject;
        });

        img.src = dataUrl;

        await promise;

        canvas.width = Math.round(img.width * middle);
        canvas.height = Math.round(img.height * middle);
        context.scale(canvas.width / img.width, canvas.height / img.height);
        context.drawImage(img, 0, 0);
        file = await urltoFile(canvas.toDataURL(), "test.png", "image/png");

        if (file.size / 1000 < targetFileSizeKb - maxDeviation) {
            low = middle;
        } else if (file.size / 1000 > targetFileSizeKb) {
            high = middle;
        }

        middle = (low + high) / 2;
        result = canvas.toDataURL();
    }

    return result;
}

const createHttpLink = (stage, apiHost, path) => {
    const uri = stage === "local" ? `http://${apiHost}/${path}` : `https://${apiHost}/${path}`;
    return new HttpLink({
        uri,
        credentials: "include",
    });
};

export const generateClientLinks = (stage, apiHost) => {
    const clientLinkMap = {
        adminAuth: createHttpLink(stage, apiHost, "admin/authenticate/v2"),
        adminCategory: createHttpLink(stage, apiHost, "admin/category/v2"),
        adminUpload: createHttpLink(stage, apiHost, "admin/upload/v2"),
        adminContent: createHttpLink(stage, apiHost, "admin/content/v2"),
        adminUser: createHttpLink(stage, apiHost, "admin/user/v2"),
        adminSettings: createHttpLink(stage, apiHost, "admin/settings/v2"),
        adminCommunication: createHttpLink(stage, apiHost, "admin/communication/v2"),
        adminNotification: createHttpLink(stage, apiHost, "admin/notification/v2"),
        adminEvents: createHttpLink(stage, apiHost, "admin/events/v2"),
        adminFeedback: createHttpLink(stage, apiHost, "admin/feedback/v2"),
        adminPushNotification: createHttpLink(stage, apiHost, "admin/push-notification/v2"),
    };
    return clientLinkMap;
};
