/* global $ */

import React, { useMemo, Fragment, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, from, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import PropTypes from "prop-types";
import Toast from "../Toast";
import { TOAST_LIST } from "../../config/toast.config";
import { MESSAGE_CONFIG } from "../../config/messages-config";
import { generateClientLinks } from "../../utils/convertor";

const stage = process.env.REACT_APP_STAGE;
const apiHost = process.env.REACT_APP_API_HOST;

const clientLinkMap = generateClientLinks(stage, apiHost);

const createSplitLink = (clientLinkMapping) => {
    return new ApolloLink((operation, forward) => {
        const link = clientLinkMapping[operation.getContext().clientName];
        return link.request(operation, forward);
    });
};

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    },
    mutate: {
        errorPolicy: "all",
    },
};

const CustomApolloProvider = ({ children }) => {
    const [toastList, setToastList] = useState([]);
    const [toastMsg, setToastMsg] = useState("");

    const errorLink = onError(({ graphQLErrors, networkError, response }) => {
        if (graphQLErrors) {
            for (const err of graphQLErrors) {
                const { message, locations, path, extensions } = err;
                console.log(
                    `[GraphQL error]: Message: ${message}, Code: ${extensions.code}, Location: ${locations}, Path: ${path}`,
                );

                switch (extensions.code) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case "UNAUTHENTICATED":
                        $("#logoutAlert").modal("show");
                        break;
                    case "FORBIDDEN":
                        console.log("unauthorized access");
                        $("#unauthorizedAlert").modal("show");
                        // handleLogoutSession();
                        break;
                    default:
                        setToastMsg(message);
                        break;
                    // if (visitedPathRef.current !== path[0]) {
                    //     setToastList([
                    //         {
                    //             ...TOAST_LIST.danger,
                    //             title: MESSAGE_CONFIG[message]
                    //                 ? MESSAGE_CONFIG[message]
                    //                 : message,
                    //         },
                    //     ]);
                    // } else console.log(`Path ${path} has been visited already`);

                    // visitedPathRef.current = path ? path[0] : null;
                }
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const apolloClient = useMemo(
        () =>
            new ApolloClient({
                // The `from` function combines an array of individual links
                // into a link chain
                link: from([errorLink, createSplitLink(clientLinkMap)]),
                cache: new InMemoryCache(),
                defaultOptions,
            }),
        [],
    );

    useEffect(() => {
        if (toastMsg) {
            setToastList([
                {
                    ...TOAST_LIST.danger,
                    title: MESSAGE_CONFIG[toastMsg] ? MESSAGE_CONFIG[toastMsg] : toastMsg,
                },
            ]);
            setToastMsg(null);
        }
    }, [toastMsg]);

    return useMemo(() => {
        return (
            <Fragment>
                <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
                <Toast
                    toastList={toastList}
                    position={"top-right"}
                    autoDelete={true}
                    dismissTime={parseInt(5000, 10)}
                />
            </Fragment>
        );
    }, [toastList]);
};

CustomApolloProvider.propTypes = {
    children: PropTypes.node,
};

export default CustomApolloProvider;
