export const DEFAULT_USER_AUTH = {
    userId: 0,
    email: "",
    username: "",
    authenticated: false,
    admin: false,
};

export const maxFileSize = 1024 * 1024 * 500;

export const requestDetailsMapping = {
    email: "Email",
    full_name: "Full Name",
    location: "Place and Country",
    gender: "Gender",
    profession: "Qualification and Profession",
    languages_speak: "Spoken Languages",
    posts_frequency: "How many days per week can you consistently upload posts?",
    social_media_link:
        "Could you share the link to your existing social media dawah page? This is mandatory for validating your profile. Submissions without dawah page links will not be processed",
    invitee_information:
        "Who invited you to Emaan Reviver? kindly mention their email or Instagram profile link or any other contact details",
    practice_islam: "Are you upon the Manhaj [methodology] of the Salaf-e-Saliheen?",
    resources:
        "Could you share the resources you rely on for learning and practising Islam? This could include scholars, books, websites, or apps. If it’s a website or an app, please provide the link. For scholars, please mention their name and provide a related video link. For books, just the name would be great",
    expectations: "What are your expectations from the Emaan Reviver app?",
    rate_your_experience: "How would you rate your experience with our form submission?",
    islamic_aqeeda: "Briefly describe your Islamic Aqeeda [belief] in a few words?",
    content_authenticity:
        "Why do you believe it's important to verify the authenticity of content before uploading it on online platforms?",
    specific_resources:
        "Which specific resources do you refer to for verifying the authenticity of Islamic content?",
    believes_principles:
        "In your exploration of knowledge and practice of Islam, which scholarly perspective or school of thought aligns closely with your beliefs and principles?",
    scholars:
        "Which respected Scholars or Ustadhs have been instrumental in shaping your understanding and providing guidance in your pursuit of Islamic knowledge?",
    scholar_reasons:
        "Can you explain why you think those Scholars or Ustadhs played a key role in helping you understand and learn about Islam?",
    scholars_social_media_links:
        "Please provide links to their social media profiles, online resources, or any video links that showcase the teachings and insights of the respected Scholars or Ustadhs you mentioned?",
    authenticity_challenges:
        "In the age of misinformation, how do you tackle challenges to authenticity and ensure the content aligns with Islamic Shariyah?",
};
