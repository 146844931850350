import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useBrowserTitle } from "../custom-hooks/BrowserTitle";

export const BrowserTitleContext = createContext({
    browserTitle: "Emaan Reviver",
    setBrowserTitle: () => {},
});

export const BrowserTitleContextProvider = ({ children }) => {
    const { browserTitle, setBrowserTitle } = useBrowserTitle(`Emaan Reviver`);

    return (
        <BrowserTitleContext.Provider
            value={{
                browserTitle,
                setBrowserTitle,
            }}
        >
            {children}
        </BrowserTitleContext.Provider>
    );
};

BrowserTitleContextProvider.propTypes = {
    children: PropTypes.node,
};
