import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const WsSocketContext = createContext({
    wsSocket: null,
    setWsSocket: () => {},
});

export const WsSocketContextProvider = ({ children }) => {
    const [wsSocket, setWsSocket] = useState(null);

    return (
        <WsSocketContext.Provider
            value={{
                wsSocket,
                setWsSocket,
            }}
        >
            {children}
        </WsSocketContext.Provider>
    );
};

WsSocketContextProvider.propTypes = {
    children: PropTypes.node,
};
