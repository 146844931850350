export const MESSAGE_CONFIG = {
    REPORT_ASSET: {
        btnText: "Mark as safe content",
        type: "Content reporting",
    },
    USER_DELETION: {
        btnText: "Mark as read",
        type: "User deleted",
    },
    CATEGORY_DELETION: {
        btnText: "Mark as read",
        type: "Category deleted",
    },
    CONTENT_DELETION: {
        btnText: "Mark as read",
        type: "Content deleted",
    },
    SUB_CATEGORY_UPDATION: {
        btnText: "Mark as read",
        type: "Sub category updated",
    },
    MEDIA_FILES_PROCESSED: {
        btnText: "Mark as read",
        type: `Media files has been processed`,
    },
    MEDIA_FILES_UNPROCESSED: {
        btnText: "Mark as read",
        type: `Media files process has failed`,
    },
    EMAIL_EXISTS: `Email already exists`,
    MOBILE_EXISTS: `Mobile already exists`,
    REGISTRATION_NOT_CONFIRMED: `Registration is not confirmed yet`,
    USER_NOT_EXISTS: `Please signup first`,
    USER_PASSWORD_INCORRECT: `Password incorrect`,
    ACTIVATION_CODE_EXPIRED: `Your activation code has been expired. New code has been sent to your email`,
    INVALID_ACTIVATION_CODE: `Invalid activation code. Please use the correct code`,
    ADMIN_APPROVAL_REQUIRED: `Our admins will approve your profile soon Insha Allah`,
    NO_RESOURCE_FOUND: `You dont have permission to delete this content`,
    PERMISSION_DENIED: `You dont have permission to perform this action`,
    USER_PERMISSION_DENIED: `Permission denied. Only Content Creators and Admins has access to this application`,
    USER_ALREADY_VERIFIED: `User is already verified`,
    USER_NOT_VERIFIED: `User is not verified`,
    USER_ALREADY_ADMIN: `User is already an admin`,
    USER_NOT_ADMIN: `User is not added as admin`,
    USER_MADE_ADMIN: `User is made as admin`,
    USER_ALREADY_CC: `User is already a content creator`,
    USER_NOT_CC: `User is not added as content creator`,
    USER_MADE_CC: `User is made as content creator`,
    ATTRIBUTOR_ADDED: `Attributor has been added successfully`,
    ATTRIBUTOR_UPDATED: `Attributor has been updated successfully`,
    ATTRIBUTOR_DELETED: `Attributor has been deleted successfully`,
    REQUEST_ALREADY_APPROVED: `Request has been already approved`,
    NO_REQUEST_DATA_FOUND: `No request data found`,
    REQUEST_APPROVED: `Request has been approved successfully`,
    REQUEST_DENIED: `Request has been denied`,
};
