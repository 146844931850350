/* global $ */

import { useCallback, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
// import { FacebookLoginClient } from "../components/FacebookLogin/facebook-login.client";
import { useGoogleLogout } from "../components/GoogleLogin";
import { GRAPHQL_SCHEMA } from "../config/gql-schemas";
import { removeItem } from "../utils/LocalStorage";
import { AuthContext } from "../context";
import useRedirectHandler from "./redirectHandler";

export default function useLogoutHandler() {
    const { setUnauthStatus, setToastList, setGlobalLoading } = useContext(AuthContext);

    const [_logoutUser] = useLazyQuery(GRAPHQL_SCHEMA.logoutUser);

    const { redirectToBase } = useRedirectHandler();

    const onFailure = (res) => {
        console.log("Failure", res);
    };

    const onLogoutSuccess = (res) => {
        console.log(res);
    };

    const { signOut } = useGoogleLogout({
        onFailure,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onLogoutSuccess,
    });

    const logout = useCallback(async () => {
        setGlobalLoading(true);
        console.log("Your logging out of application");
        $("#logoutAlert").modal("hide");
        $("#logoutConfirmationModal").modal("hide");
        $("#unauthorizedAlert").modal("hide");
        // setUserAuth(DEFAULT_USER_AUTH);
        // FacebookLoginClient.logout(() => {
        //     console.log("logout completed!");
        // });
        signOut();
        setUnauthStatus();
        setToastList([]);
        await _logoutUser();
        removeItem("isAdmin");
        // setisProfile(false);
        setGlobalLoading(false);
        redirectToBase();
    });

    return { logout };
}
