import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ErrorContext = createContext({
    dataError: {
        error: false,
        errorMsg: "",
    },
    setDataError: () => {},
});

export const ErrorContextProvider = ({ children }) => {
    const [dataError, setDataError] = useState({ error: false, errorMsg: "" });

    return (
        <ErrorContext.Provider
            value={{
                dataError,
                setDataError,
            }}
        >
            {children}
        </ErrorContext.Provider>
    );
};

ErrorContextProvider.propTypes = {
    children: PropTypes.node,
};
